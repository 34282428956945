import { toastWebview, toastWebviewResponse } from 'components/ToastWebview'
import {
  checkIsDriverArrived,
  checkIsDriverCancelled,
  checkIsDriverCompleted,
} from 'components/TrackingMap/utils'

import {
  PostGetRouteRequest,
  getTrackingDetailAPI,
  postGetRouteCustomerAPI,
} from 'utils/apiList/tracking'
import { polylineDecode } from 'utils/polylineDecode'

type DriverPostion = {
  driver_id: number
  driver_status: string
  driver_name: string
  lat: number
  lon: number
  timestamp: number
}

type Order = {
  lat: number
  lon: number
  status: string
  arrival_estimation: string
  address_detail: string
  address_notes: string
  driver: {
    name: string
    plate_number: string
    phone_number: string
  }
}

export type LiveTrackingOrderSlice = {
  isLoading: boolean
  isLoadingInformationDetail: boolean
  isError: boolean
  isErrorInformationDetail: boolean
  isCompleted: boolean
  isCancelled: boolean
  driverPosition: DriverPostion | null
  driverRoute: [number, number][]
  order: Order | null
  setIsLoading: (isLoading: boolean) => void
  setIsError: (isError: boolean) => void
  setDriverPosition: (
    payload: DriverPostion,
    callback?: (
      driverHasAdded: boolean,
      route: [number, number][],
      orderPosition?: [number, number]
    ) => void
  ) => void
  setOrder: (payload: Order, callback?: () => void) => void
  setRoute: (payload: [number, number][]) => void
  setOrderDetailFromAdmin: (payload: {
    latitude: number
    longitude: number
    addressDetail: string
    address: string
    driverNote: string
    status: string
  }) => void
  fetchGetOrderDetail: (
    id: number,
    options?: Partial<{
      onError: (error?: { message?: string }) => void
    }>
  ) => void
  fetchPostGetRoute: (
    payload: PostGetRouteRequest
  ) => Promise<[number, number][]>
}

export const createLiveTrackingOrderSlice: StoreSlice<
  LiveTrackingOrderSlice
> = (set, get) => ({
  isLoading: true,
  isLoadingInformationDetail: false,
  isError: false,
  isErrorInformationDetail: false,
  isCompleted: false,
  isCancelled: false,
  order: null,
  driverPosition: null,
  driverRoute: [],
  setIsLoading: (isLoading) => {
    set((state) => {
      state.isLoading = isLoading
    })
  },
  setIsError: (isError) => {
    set((state) => {
      state.isError = isError
    })
  },
  setDriverPosition: (payload, callback) => {
    const { order, driverPosition, driverRoute } = get()

    set((state) => {
      state.driverPosition = payload
      state.isError = false
    })

    if (checkIsDriverCompleted(payload.driver_status)) {
      set((state) => {
        state.isCompleted = true
      })
    }

    if (callback) {
      callback(
        !!driverPosition,
        driverRoute,
        order ? [order.lat, order.lon] : undefined
      )
    }
  },
  setOrder: (payload, callback) => {
    set((state) => {
      state.order = payload
    })

    if (callback) callback()
  },
  setRoute: (payload: [number, number][]) => {
    set((state) => {
      state.driverRoute = payload
    })
  },
  setOrderDetailFromAdmin: (payload) => {
    const { latitude, longitude, addressDetail, address, driverNote, status } =
      payload
    if (latitude && longitude) {
      set((state) => {
        state.order = {
          lat: latitude,
          lon: longitude,
          address_detail: `${addressDetail}, ${address}`,
          address_notes: driverNote,
          status,
          driver: {
            name: '',
            phone_number: '',
            plate_number: '',
          },
          arrival_estimation: '',
        }
      })
    }
  },
  fetchGetOrderDetail: async (id, options) => {
    try {
      set((state) => {
        state.isLoadingInformationDetail = true
      })

      const { order } = get()

      const { data } = await getTrackingDetailAPI(id)

      const { destination } = data
      const { latitude, longitude } = destination.coordinate

      if (latitude && longitude) {
        if (latitude !== order?.lat && longitude !== order?.lon) {
          set((state) => {
            state.order = {
              lat: latitude,
              lon: longitude,
              driver: data.driver,
              address_detail: data.destination.address_detail,
              address_notes: data.destination.address_notes,
              arrival_estimation: data.arrival_estimation,
              status: data.order_status,
            }
          })
        }
      } else {
        toastWebview({
          message: 'Lokasi order tidak ditemukan',
        })
      }

      if (checkIsDriverArrived(data?.order_status)) {
        toastWebview({
          message: 'Pesanan sudah sampai dilokasi tujuan',
        })
      }

      if (checkIsDriverCompleted(data?.order_status)) {
        set((state) => {
          state.isCompleted = true
        })
      }

      if (checkIsDriverCancelled(data?.order_status)) {
        set((state) => {
          state.isCancelled = true
        })
      }

      set((state) => {
        state.isErrorInformationDetail = false
      })
    } catch (err) {
      set((state) => {
        state.isErrorInformationDetail = true
      })

      if (options?.onError) {
        options.onError(err as { message: string })
      }
      toastWebviewResponse(err as ErrorResponse, 'Gagal mendapatkan data')
    } finally {
      set((state) => {
        state.isLoadingInformationDetail = false
      })
    }
  },
  fetchPostGetRoute: async (payload) => {
    try {
      const response = await postGetRouteCustomerAPI(payload)

      const shapeEncoded = response.data.data.trip.detail?.[0].shape
      if (shapeEncoded) {
        const route = polylineDecode(shapeEncoded)

        return route
      }

      return []
    } catch (err) {
      toastWebview({ message: 'Gagal mendapatkan rute' })
      return []
    }
  },
})
