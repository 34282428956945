export const POLYGON_COLOR_BY_SLA = [
  {
    slaName: '15',
    color: '#8b8b8b',
  },
  {
    slaName: '30',
    color: '#0401f1',
  },
  {
    slaName: '45',
    color: '#f10722',
  },
]

export const MAPS_POLYGON_TYPE = 'Polygon'
