import axios from 'axios'
import { X_DEVICE_DESKTOP } from 'constants/device'
import {
  getNativeHeaders,
  getTokenCookies,
  removeTokenCookies,
  setTokenCookies,
} from 'utils/cookie'

import { queryParamsURL } from 'utils/queryParamsURL'
import { generateXDeviceId } from 'utils/userAgent'
import { axiosErrorLoggerInterceptor } from './errorLog'

const axiosInstanceApiGateway = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
})

axiosInstanceApiGateway.interceptors.request.use(
  async (config) => {
    const { authToken } = queryParamsURL.get()
    const nativeHeaders = getNativeHeaders()

    const credentials =
      authToken || nativeHeaders?.Authorization || getTokenCookies()
    const xDevice = nativeHeaders?.['X-DEVICE'] || X_DEVICE_DESKTOP
    const xDeviceId =
      nativeHeaders?.['X-DEVICE-ID'] || (await generateXDeviceId())

    if (config.headers) {
      config.headers['Content-Type'] = 'application/json'
      config.headers.Authorization = `Bearer ${credentials}`
      config.headers['x-device'] = xDevice
      config.headers['x-device-id'] = xDeviceId
    }

    if (credentials) {
      setTokenCookies(credentials)
      queryParamsURL.remove(['authToken'])
    }

    return config
  },
  (error) => {
    if (error?.request?.status === 401) {
      removeTokenCookies()
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)

axiosInstanceApiGateway.interceptors.response.use(
  (response) => response,
  (error) => {
    axiosErrorLoggerInterceptor(error)

    return Promise.reject(error)
  }
)

export default axiosInstanceApiGateway
