import axiosInstanceApiGateway from 'config/apiService'
import { LatLngTuple } from 'leaflet'
import { DeliveriesType, IsochroneFilterType } from 'pages/PoolingMap/type'
import { IsochroneLocationCustom } from 'store/isochroneAll'
import { IsochroneLocationKong } from 'types/isochrone'
import { ServiceLevel } from 'types/serviceLevel'
import { toastErrResponse } from 'utils/toast'

const LOCATION_POLYGON_KONG = '/location/internal/v1/polygon'
type FetchAllIsochroneKongResponse = {
  data: {
    items: IsochroneLocationKong[]
    service_level_id: ServiceLevel['service_level_id']
    service_level_name: ServiceLevel['service_level_name']
  }[]
  version: string
}

export type PoolingMapSlice = {
  isLoading: boolean
  isError: boolean
  hubLocation: LatLngTuple
  allDeliveryOrder: DeliveriesType
  mapZoom: number
  mapCenter: LatLngTuple
  hasPooling: string
  selectedOrder: DeliveriesType
  enableIsochrone: boolean
  isochroneList: IsochroneLocationCustom[]
  setEnableIsochrone: (enableIsochrone: boolean) => void
  setIsLoading: (isLoading: boolean) => void
  setIsError: (isError: boolean) => void
  setHubLocation: (hubLocation: LatLngTuple) => void
  setAllDeliveryOrder: (allDeliveryOrder: DeliveriesType) => void
  setMapZoom: (mapZoom: number) => void
  setMapCenter: (mapCenter: LatLngTuple) => void
  setHasPooling: (hasPooling: string) => void
  setSelectedOrder: (selectedOrder: DeliveriesType[number]) => void
  setReset: () => void
  setRemoveOrder: (selectedOrder: DeliveriesType[number]) => void
  fetchIsochrone: (params?: IsochroneFilterType) => void
  getNormalizedDataFromKong: (
    data: FetchAllIsochroneKongResponse['data']
  ) => IsochroneLocationCustom[]
}

export const createPoolingMapSlice: StoreSlice<PoolingMapSlice> = (
  set,
  get
) => ({
  isLoading: true,
  isError: false,
  hubLocation: [-6.175392, 106.827153],
  allDeliveryOrder: [],
  mapZoom: 14,
  mapCenter: [-6.175392, 106.827153],
  hasPooling: '0',
  selectedOrder: [],
  isochroneList: [],
  enableIsochrone: false,
  setEnableIsochrone: (enableIsochrone) => {
    set((state) => {
      state.enableIsochrone = enableIsochrone
    })
  },
  setIsLoading: (isLoading) => {
    set((state) => {
      state.isLoading = isLoading
    })
  },
  setIsError: (isError) => {
    set((state) => {
      state.isError = isError
    })
  },
  setHubLocation: (hubLocation) => {
    set((state) => {
      state.hubLocation = hubLocation
    })
  },
  setAllDeliveryOrder: (allDeliveryOrder) => {
    set((state) => {
      state.allDeliveryOrder = allDeliveryOrder
    })
  },
  setMapZoom: (mapZoom) => {
    set((state) => {
      state.mapZoom = mapZoom
    })
  },
  setMapCenter: (mapCenter) => {
    set((state) => {
      state.mapCenter = mapCenter
    })
  },
  setHasPooling: (hasPooling) => {
    set((state) => {
      state.hasPooling = hasPooling
    })
  },
  setSelectedOrder: (selectedOrder) => {
    set((state) => {
      const ordersToAdd =
        selectedOrder.pool_number !== '0'
          ? state.allDeliveryOrder.filter(
              (order) => order.pool_number === selectedOrder.pool_number
            )
          : [selectedOrder]

      state.selectedOrder = [...state.selectedOrder, ...ordersToAdd]
      state.hasPooling = selectedOrder.pool_number || '0'
    })
  },
  setRemoveOrder: (selectedOrder) => {
    set((state) => {
      state.selectedOrder = state.selectedOrder.filter(
        (order) => order.order_id !== selectedOrder.order_id
      )
    })
  },
  setReset: () => {
    set((state) => {
      state.selectedOrder = []
      state.hasPooling = '0'
    })
  },
  getNormalizedDataFromKong: (data) => {
    let isochroneList: IsochroneLocationCustom[] = []

    data.forEach((el) => {
      const transformedItems = el?.items?.length
        ? el.items.map((item) => ({
            location_id: item.location_id,
            location_name: item.location_name,
            location_latitude: item.location_latitude,
            location_longitude: item.location_longitude,
            location_city: item.location_name,
            location_address: item.location_address,
            polygons: {
              type: item.polygons.type,
              coordinates: [
                item.polygons.coordinates
                  ? item.polygons.coordinates[0].coordinate_point.map(
                      (elCoordinate) => elCoordinate.point
                    )
                  : [],
              ],
            },
            service_level_id: el.service_level_id,
            service_level_name: el.service_level_name,
          }))
        : []

      isochroneList = [
        ...isochroneList,
        ...(transformedItems.length ? transformedItems : []),
      ]
    })

    return isochroneList
  },
  fetchIsochrone: async (params) => {
    const { getNormalizedDataFromKong } = get()
    try {
      const appendToParams = (val: string, key: string) => {
        const splited = val.split(',')
        const joined = splited.join(`&${key}=`)

        return `${key}=${joined}`
      }

      const composeParams = `${appendToParams(
        params?.hubIds.join(',') || '',
        'location_id'
      )}&${appendToParams(params?.slaIds.join(',') || '', 'sla_id')}`

      const res = await axiosInstanceApiGateway.get(
        `${LOCATION_POLYGON_KONG}?${composeParams}`
      )

      set((state) => {
        state.isochroneList = getNormalizedDataFromKong(res.data.data)
      })
    } catch (error) {
      toastErrResponse(error, 'failed to fetch isochrone')
    }
  },
})
