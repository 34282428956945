import getBrowserFingerprint from 'get-browser-fingerprint'
import { v4 as uuidv4 } from 'uuid'

export const generateXDeviceId = async () => {
  try {
    return await getBrowserFingerprint()
  } catch {
    return uuidv4()
  }
}
