export const mapCoordinatesToLatLng = (coordinates: number[][]) => {
  const result = coordinates.map((point) => ({
    lat: point[1],
    lng: point[0],
  }))

  return result
}

export const getBoundsForPolygon = (
  paths: google.maps.MVCArray<google.maps.LatLng> | google.maps.LatLngLiteral[]
) => {
  const bounds = new google.maps.LatLngBounds()

  paths.forEach((latLng) => {
    bounds.extend(latLng)
  })

  return bounds
}

export const getApproximateCenterPolygon = (
  polygonInstance: google.maps.Polygon
) => {
  const polygonBounds = getBoundsForPolygon(polygonInstance.getPath())

  const centerPoint = polygonBounds.getCenter()

  if (
    google.maps.geometry.poly.containsLocation(centerPoint, polygonInstance)
  ) {
    // Nothing to do Centroid is in polygon use it as is
    return centerPoint
  }

  let boundsHeight = 0
  let boundsWidth = 0
  let heightIncr = 0
  const maxSearchSteps = 10
  let n = 1
  let northWest = null
  let widthIncr = 0
  let testPos = centerPoint

  // Calculate NorthWest point so we can work out height of polygon NW->SE
  northWest = new google.maps.LatLng(
    polygonBounds.getNorthEast().lat(),
    polygonBounds.getSouthWest().lng()
  )

  // Work out how tall and wide the bounds are and what our search
  // increment will be
  boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    polygonBounds.getSouthWest()
  )
  heightIncr = boundsHeight / maxSearchSteps

  boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(
    northWest,
    polygonBounds.getNorthEast()
  )
  widthIncr = boundsWidth / maxSearchSteps

  // Expand out from Centroid and find a point within polygon at
  // 0, 90, 180, 270 degrees
  for (; n <= maxSearchSteps; n++) {
    // Test point North of Centroid
    testPos = google.maps.geometry.spherical.computeOffset(
      centerPoint,
      heightIncr * n,
      0
    )
    if (google.maps.geometry.poly.containsLocation(testPos, polygonInstance)) {
      break
    }

    // Test point East of Centroid
    testPos = google.maps.geometry.spherical.computeOffset(
      centerPoint,
      widthIncr * n,
      90
    )
    if (google.maps.geometry.poly.containsLocation(testPos, polygonInstance)) {
      break
    }

    // Test point South of Centroid
    testPos = google.maps.geometry.spherical.computeOffset(
      centerPoint,
      heightIncr * n,
      180
    )
    if (google.maps.geometry.poly.containsLocation(testPos, polygonInstance)) {
      break
    }

    // Test point West of Centroid
    testPos = google.maps.geometry.spherical.computeOffset(
      centerPoint,
      widthIncr * n,
      270
    )
    if (google.maps.geometry.poly.containsLocation(testPos, polygonInstance)) {
      break
    }
  }

  return testPos
}
