import { useEffect } from 'react'
import { usePoolingMapStore } from 'store/poolingMap'

const useIsochrone = () => {
  const { isochroneList } = usePoolingMapStore()
  useEffect(() => {
    // fetchIsochrone()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    isochroneList,
  }
}
export default useIsochrone
